import './App.css';
import logo from './logo.png'

function App() {
  return (
    <div style={{backgroundImage: `url(${logo})`, height: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: 'cover'}}>
      </div> 
    );
}

export default App;
